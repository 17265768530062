<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2"> {{ $t("message.clientBonusSystem") }} </div>
              <div class="block-sarche">
                <div class="header__search">
                  
                   <crm-input
                        :size="'small'"
                        :class="mode ? 'input__day' : 'input__night'"
                        :className="'w100'"
                        v-model="filterForm.search"
                        :icon="'el-icon-search'"
                    ></crm-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right">

             <crm-create-and-column-settings
                  :permission="$options.name"
                  :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                  @c-create="drawerCreate = true"
                  :columns="columns"
                  @c-change="updateColumn"
              >
              </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <el-form ref="form" :model="clientData" label-position="top">
        <div class="card-table-header table-crm-smart custom__scroll">
          <el-tabs v-model="activeTab" @tab-click="handleTabClick">
            <el-tab-pane v-loading="loadingData" :label="$t('message.client_bonus')" name="client">
              <div class="card-table-header table-crm-smart custom__scroll">
                <div class="mm__space_beetween table-my-code mb-2">
                  <span class="mm__header_bonus">{{ $t('message.client_bonus') }}</span>
                  <div v-if="!(selectedItem && selectedItem.id)">
                    <el-button @click="addBonus()" type="success" icon="el-icon-plus"></el-button>
                    <el-button @click="saveClientBonus()" type="primary">{{ $t('message.save') }}</el-button>
                  </div>
                </div>
          
                <div class="mm__bonus_create_back">
                  <el-row v-if="clientData && clientData.userBonuses && clientData.userBonuses.length > 0" :gutter="20" class="mm__create_bonus m-2">
                      <el-col :span="12">
                          <span class="font-bold">{{ $t('message.clients') }}</span>
                      </el-col>
                      <el-col :span="6">
                        <span class="font-bold">{{ $t('message.percentage') }}</span>
                      </el-col>
                      <!-- <el-col v-if="columns.min_deal_sum.show" :span="4">
                        <span class="font-bold">{{ $t('message.min_deal_sum') }}</span>
                      </el-col>
                      <el-col v-if="columns.max_deal_sum.show" :span="4">
                        <span class="font-bold">{{ $t('message.max_deal_sum') }}</span>
                      </el-col> -->
                      <el-col :span="6" class="text-right">
                        <span class="font-bold">{{ $t('message.settings') }}</span>
                      </el-col>
                  </el-row>
                  
                  <el-row :gutter="20" class="mm__create_bonus" v-for="(bonus, index) in clientData.userBonuses">
                      <el-col :span="12">
                        <el-form-item :prop="'userBonuses.' + index + '.user_id'"
                            :rules="{ required: true, message: $t('message.please_select_input', {input: $t('message.client')}), trigger: 'change' }"
                          >
                          <el-select
                              :key="'bonus_'+index"
                              v-model="bonus.user_id"
                              @input="dispatch"
                              :placeholder="$t('message.user')"
                              filterable
                              clearable
                              remote 
                              :loading="loadingUsers"
                              :remote-method="searchInventoryWithLoad"
                              class="w-100"
                          >
                              <el-option
                                  v-for="(user, index) in users"
                                  :key="'users-' + index"
                                  :label="user.name"
                                  :value="user.id"
                              ></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item :prop="'userBonuses.' + index + '.percentage'"
                          :rules="{ required: true, message: $t('message.please_enter_input', {input: $t('message.percentage')}), trigger: 'change' }"
                        >
                              <el-input-number class="w-100" v-model="bonus.percentage" :min="0" :precision="2" :step="0.5"></el-input-number>
                        </el-form-item>

                      </el-col>
                      <!-- <el-col v-if="columns.min_deal_sum.show" :span="4">
                        <el-form-item :prop="'userBonuses.' + index + '.min_deal_sum'"
                          :rules="{ required: false, message: $t('message.please_enter_input', {input: $t('message.min_deal_sum')}), trigger: 'change' }"
                        >
                          <el-input v-model="bonus.min_deal_sum" :min="0" type="number" :placeholder="$t('message.min_deal_sum')"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col v-if="columns.max_deal_sum.show" :span="4">
                        <el-form-item :prop="'userBonuses.' + index + '.max_deal_sum'"
                          :rules="{ required: false, message: $t('message.please_enter_input', {input: $t('message.max_deal_sum')}), trigger: 'change' }"
                        >
                          <el-input v-model="bonus.max_deal_sum" :min="0" type="number" :placeholder="$t('message.max_deal_sum')"></el-input>
                        </el-form-item>
                      </el-col> -->
                      <el-col :span="6">
                        <el-form-item>
                          <div class="text-right">
                            <el-button @click="addBonus(index)" type="success" icon="el-icon-plus"></el-button>
                            <el-button @click="removeBonus(index)" type="danger" icon="el-icon-delete"></el-button>
                          </div>
                        </el-form-item>
                      </el-col>
                  </el-row>
                </div>
              </div>

              <div class="card-table-header table-crm-smart custom__scroll">
                <table 
                  class="table-my-code table-bordered"
                  :class="mode ? 'table__myday' : 'table__mynight'"
                >
                  <thead>
                    <tr>
                        <th class="w50p" v-if="columns.id.show">
                            {{ columns.id.title }}
                        </th>

                        <th v-if="columns.user_id.show">
                            {{ columns.user_id.title }}
                        </th>

                        <th v-if="columns.user_id.show">
                            {{ $t('message.phone') }}
                        </th>

                        <th v-if="columns.percentage.show">
                            {{ columns.percentage.title }}
                        </th>
                        <th v-if="columns.min_deal_sum.show">
                            {{ columns.min_deal_sum.title }}
                        </th>
                        <th v-if="columns.max_deal_sum.show">
                            {{ columns.max_deal_sum.title }}
                        </th>
                        <th v-if="columns.created_at.show">
                            {{ columns.created_at.title }}
                        </th>

                        <th v-if="columns.updated_at.show">
                            {{ columns.updated_at.title }}
                        </th>

                        <th v-if="columns.settings.show">
                            {{ columns.settings.title }}
                        </th>
                    </tr>

                    <tr class="filter_sorche">
                      <th v-if="columns.id.show">
                          <el-input
                              clearable
                              size="mini"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.id"
                              :placeholder="columns.id.title"
                              class="id_input"
                          ></el-input>
                      </th>

                      <th v-if="columns.user_id.show">
                        <el-select
                            :value="filterForm.user_id"
                            @input="dispatch"
                            size="mini"
                            :placeholder="$t('message.user')"
                            filterable
                            clearable
                            remote 
                            :loading="loadingUsers"
                            :remote-method="searchInventoryWithLoad"
                            class="w-100"
                        >
                            <el-option
                                v-for="(user, index) in users"
                                :key="'users-' + index"
                                :label="user.name"
                                :value="user.id"
                            ></el-option>
                        </el-select>
                      </th>

                      <th v-if="columns.user_id.show">
                      </th>

                      <th v-if="columns.percentage.show">
                          <crm-input
                              :placeholder="columns.percentage.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.percentage"
                          ></crm-input>
                      </th>

                      <th v-if="columns.min_deal_sum.show">
                          <crm-input
                              :placeholder="columns.min_deal_sum.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.min_deal_sum"
                          ></crm-input>
                      </th>
                      <th v-if="columns.max_deal_sum.show">
                          <crm-input
                              :placeholder="columns.max_deal_sum.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.max_deal_sum"
                          ></crm-input>
                      </th>
                      <th v-if="columns.created_at.show">
                          <crm-date-picker
                              :placeholder="columns.created_at.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.created_at"
                          ></crm-date-picker>
                      </th>

                      <th v-if="columns.updated_at.show">
                          <crm-date-picker
                              :placeholder="columns.updated_at.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.updated_at"
                          ></crm-date-picker>
                      </th>

                      <th
                          class="settinW"
                          v-if="columns.settings.show"
                      ></th>
                  </tr>
                  </thead>

                  <transition-group name="flip-list" tag="tbody">
                    <tr v-for="bonus in list" :key="bonus.id" class="cursor-pointer">

                        <td v-if="columns.id.show">
                            {{ bonus.id }}
                        </td>

                        <td v-if="columns.user_id.show">
                            
                            <el-select
                                v-if="selectedItem && selectedItem.id == bonus.id"
                                v-model="selectedItem.user_id"
                                @input="dispatch"
                                :placeholder="$t('message.user')"
                                :size="'mini'"
                                filterable
                                clearable
                                remote 
                                :loading="loadingUsers"
                                :remote-method="searchInventoryWithLoad"
                                class="w-100"
                            >
                                <el-option
                                    v-for="(user, index) in users"
                                    :key="'users-' + index"
                                    :label="user.name"
                                    :value="user.id"
                                ></el-option>
                            </el-select>
                            <span v-else>
                              {{ bonus.user ? bonus.user.name : '' }}
                            </span>
                        </td>

                        <td v-if="columns.user_id.show">
                            <span v-if="!selectedItem || (selectedItem && selectedItem.user_id == bonus.user_id)">
                              {{ bonus.user ? bonus.user.phone : '' }}
                            </span>
                        </td>

                        <td v-if="columns.percentage.show">
                            <span v-if="selectedItem && selectedItem.id == bonus.id">
                              <el-input-number class="w-100" v-model="selectedItem.percentage" size="mini" :min="0" :precision="2" :step="0.5"></el-input-number>
                            </span>

                            <span v-else>
                              {{ bonus.percentage }} %
                            </span>
                        </td>

                        <td v-if="columns.min_deal_sum.show">
                            <span v-if="selectedItem && selectedItem.id == bonus.id">
                              <el-input v-model="selectedItem.min_deal_sum" :min="0" type="number" size="mini" :placeholder="$t('message.min_deal_sum')"></el-input>
                            </span>
                            <span v-else>
                              {{ bonus.min_deal_sum }}
                            </span>
                        </td>

                        <td v-if="columns.max_deal_sum.show">
                            <span v-if="selectedItem && selectedItem.id == bonus.id">
                                <el-input v-model="selectedItem.max_deal_sum" :min="0" type="number" size="mini" :placeholder="$t('message.max_deal_sum')"></el-input>
                            </span>
                            <span v-else>
                              {{ bonus.max_deal_sum }}
                            </span>
                        </td>
                      
                        <td v-if="columns.created_at.show">
                            {{ bonus.created_at }}
                        </td>

                        <td v-if="columns.updated_at.show">
                            {{ bonus.updated_at }}
                        </td>
                        <td v-if="columns.settings.show">
                            <el-button-group>
                              <el-button :loading="loadingSaveData" @click="edit(bonus)" v-if="!(selectedItem && selectedItem.id) && permissions.some(per => per.slug == 'clientBonusSystem.update')" size="mini" type="primary" icon="el-icon-edit"></el-button>
                              <el-button :loading="loadingSaveData" @click="deleteBonus(bonus)" v-if="!(selectedItem && selectedItem.id) && permissions.some(per => per.slug == 'clientBonusSystem.delete')" size="mini" type="danger" icon="el-icon-delete"></el-button>

                              <el-button :loading="loadingSaveData" @click="updateBonus(bonus)" v-if="selectedItem && selectedItem.id == bonus.id" size="mini" type="success" icon="el-icon-check"></el-button>
                              <el-button :loading="loadingSaveData" @click="closeBonusUpdate()" v-if="selectedItem && selectedItem.id == bonus.id" size="mini" type="danger" icon="el-icon-close"></el-button>
                            </el-button-group>
                        </td>
                    </tr>
                  </transition-group>
                </table>
                <div class="my___pagination">
                  <crm-pagination
                    @c-change="updatePagination"
                    :class="mode ? 'pagination__day' : 'pagination__night'"
                    :pagination="pagination"
                  ></crm-pagination>
                  <!-- <Pagination /> -->
                </div>
              </div>
            </el-tab-pane>

            <el-tab-pane v-loading="loadingData" :label="$t('message.default_bonus')" name="default">
              <div class="card-table-header table-crm-smart custom__scroll">
                <div class="mm__space_beetween table-my-code mb-2">
                  <span class="mm__header_bonus">{{ $t('message.default_bonus') }}</span>
                  <div v-if="!(selectedItem && selectedItem.id)">
                    <!-- <el-button @click="addBonus(null, true)" type="success" icon="el-icon-plus"></el-button> -->
                    <el-button v-if="list.length == 0" :loading="loadingSaveData" @click="saveClientBonus(true)" type="primary">{{ $t('message.save') }}</el-button>
                  </div>
                </div>
                <div class="mm__bonus_create_back" v-if="list.length == 0 && !loadingData">
                  <el-row :gutter="20" class="mm__create_bonus" v-for="(bonus, index) in clientData.defaultBonuses">
                      <el-col :span="12">
                        <el-form-item>
                          <span class="font-bold"> {{ $t('message.default_bonus') }} </span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item :prop="'defaultBonuses.' + index + '.percentage'"
                          :rules="{ required: true, message: $t('message.please_enter_input', {input: $t('message.percentage')}), trigger: 'change' }"
                        >
                              <el-input-number class="w-100" v-model="bonus.percentage" :min="0" :precision="2" :step="0.5"></el-input-number>
                        </el-form-item>

                      </el-col>
                      <!-- <el-col :span="4">
                        <el-form-item :prop="'defaultBonuses.' + index + '.user_id'"
                          :rules="{ required: false, message: $t('message.please_enter_input', {input: $t('message.min_deal_sum')}), trigger: 'change' }"
                        >
                          <el-input v-model="bonus.min_deal_sum" :min="0" type="number" :placeholder="$t('message.min_deal_sum')"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="4">
                        <el-form-item :prop="'defaultBonuses.' + index + '.user_id'"
                          :rules="{ required: false, message: $t('message.please_enter_input', {input: $t('message.max_deal_sum')}), trigger: 'change' }"
                        >
                          <el-input v-model="bonus.max_deal_sum" :min="0" type="number" :placeholder="$t('message.max_deal_sum')"></el-input>
                        </el-form-item>
                      </el-col> -->
                      <el-col :span="6">
                        <el-form-item>
                          <div class="text-right">
                            <!-- <el-button @click="addBonus(index, true)" type="success" icon="el-icon-plus"></el-button> -->
                            <el-button @click="removeBonus(index, true)" type="danger" icon="el-icon-delete"></el-button>
                          </div>
                        </el-form-item>

                      </el-col>
                  </el-row>
                </div>
              </div>

              <div class="card-table-header table-crm-smart custom__scroll">
                <table 
                  class="table-my-code table-bordered"
                  :class="mode ? 'table__myday' : 'table__mynight'"
                >
                  <thead>
                    <tr>
                        <th class="w50p" v-if="columns.id.show">
                            {{ columns.id.title }}
                        </th>
                        <th v-if="columns.percentage.show">
                            {{ columns.percentage.title }}
                        </th>
                        <th v-if="columns.min_deal_sum.show">
                            {{ columns.min_deal_sum.title }}
                        </th>
                        <th v-if="columns.max_deal_sum.show">
                            {{ columns.max_deal_sum.title }}
                        </th>
                        <th v-if="columns.created_at.show">
                            {{ columns.created_at.title }}
                        </th>

                        <th v-if="columns.updated_at.show">
                            {{ columns.updated_at.title }}
                        </th>

                        <th v-if="columns.settings.show">
                            {{ columns.settings.title }}
                        </th>
                    </tr>

                    <tr class="filter_sorche">
                      <th v-if="columns.id.show">
                          <el-input
                              clearable
                              size="mini"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.id"
                              :placeholder="columns.id.title"
                              class="id_input"
                          ></el-input>
                      </th>

                      <th v-if="columns.percentage.show">
                          <crm-input
                              :placeholder="columns.percentage.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.percentage"
                          ></crm-input>
                      </th>

                      <th v-if="columns.min_deal_sum.show">
                          <crm-input
                              :placeholder="columns.min_deal_sum.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.min_deal_sum"
                          ></crm-input>
                      </th>
                      <th v-if="columns.max_deal_sum.show">
                          <crm-input
                              :placeholder="columns.max_deal_sum.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.max_deal_sum"
                          ></crm-input>
                      </th>
                      <th v-if="columns.created_at.show">
                          <crm-date-picker
                              :placeholder="columns.created_at.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.created_at"
                          ></crm-date-picker>
                      </th>

                      <th v-if="columns.updated_at.show">
                          <crm-date-picker
                              :placeholder="columns.updated_at.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.updated_at"
                          ></crm-date-picker>
                      </th>

                      <th v-if="columns.settings.show"></th>
                    </tr>
                  </thead>

                  <transition-group name="flip-list" tag="tbody">
                    <tr v-for="bonus in list" :key="bonus.id" class="cursor-pointer" :class="(selectedItem && selectedItem.id == bonus.id) ? 'mm__updating_row' : ''">
                        <td v-if="columns.id.show">
                            {{ bonus.id }}
                        </td>

                        <td v-if="columns.percentage.show">
                            <span v-if="selectedItem && selectedItem.id == bonus.id">
                              <el-input-number class="w-100" v-model="selectedItem.percentage" size="mini" :min="0" :precision="2" :step="0.5"></el-input-number>
                            </span>

                            <span v-else>
                              {{ bonus.percentage }} %
                            </span>
                        </td>

                        <td v-if="columns.min_deal_sum.show">
                            <span v-if="selectedItem && selectedItem.id == bonus.id">
                              <el-input v-model="selectedItem.min_deal_sum" :min="0" type="number" size="mini" :placeholder="$t('message.min_deal_sum')"></el-input>
                            </span>
                            <span v-else>
                              {{ bonus.min_deal_sum }}
                            </span>
                        </td>

                        <td v-if="columns.max_deal_sum.show">
                            <span v-if="selectedItem && selectedItem.id == bonus.id">
                                <el-input v-model="selectedItem.max_deal_sum" :min="0" type="number" size="mini" :placeholder="$t('message.max_deal_sum')"></el-input>
                            </span>
                            <span v-else>
                              {{ bonus.max_deal_sum }}
                            </span>
                        </td>
                      
                        <td v-if="columns.created_at.show">
                            {{ bonus.created_at }}
                        </td>

                        <td v-if="columns.updated_at.show">
                            {{ bonus.updated_at }}
                        </td>

                        <td v-if="columns.settings.show">
                            <el-button-group>
                              <el-button :loading="loadingSaveData" @click="edit(bonus)" v-if="!(selectedItem && selectedItem.id) && permissions.some(per => per.slug == 'clientBonusSystem.update')" size="mini" type="primary" icon="el-icon-edit"></el-button>
                              <el-button :loading="loadingSaveData" @click="deleteBonus(bonus)" v-if="!(selectedItem && selectedItem.id) && permissions.some(per => per.slug == 'clientBonusSystem.delete')" size="mini" type="danger" icon="el-icon-delete"></el-button>

                              <el-button :loading="loadingSaveData" @click="updateBonus(bonus)" v-if="selectedItem && selectedItem.id == bonus.id" size="mini" type="success" icon="el-icon-check"></el-button>
                              <el-button :loading="loadingSaveData" @click="closeBonusUpdate()" v-if="selectedItem && selectedItem.id == bonus.id" size="mini" type="danger" icon="el-icon-close"></el-button>
                            </el-button-group>
                        </td>
                    </tr>
                  </transition-group>
                </table>
                <div class="my___pagination">
                  <crm-pagination
                    @c-change="updatePagination"
                    :class="mode ? 'pagination__day' : 'pagination__night'"
                    :pagination="pagination"
                  ></crm-pagination>
                  <!-- <Pagination /> -->
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-form>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import Pagination from "@/components/el-pagination";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import { mapGetters, mapActions } from "vuex";
import selectUser from "@/components/inventory/select-users";

export default {
    name: "reasons",
    mixins: [list],
    components: {
        Pagination,
        CrmCreate,
        CrmUpdate,
        selectUser
    },

    data() {
        return {
          loadingSaveData: false,
          activeTab: 'client',
          loadingUsers: false,
          clientData: {
            userBonuses: [],
            defaultBonuses: [
              {
                user_id: null,
                percentage: 0,
                min_deal_sum: null,
                max_deal_sum: null,
                currency_id: null,
              }
            ],
          },
          form: {
            user_id: null,
            percentage: 0,
            min_deal_sum: null,
            max_deal_sum: null,
            currency_id: null,
          }
        };
    },
    created(){
      this.updateUsersInventory({employee_type: 'client', per_page: 50});
      this.filterForm.default = false;
    },
    computed: {
        ...mapGetters({
            permissions: "auth/permissions",
            users: "users/inventory",
            list: "clientBonusSystem/list",
            columns: "clientBonusSystem/columns",
            pagination: "clientBonusSystem/pagination",            
            filter: "clientBonusSystem/filter",
            sort: "clientBonusSystem/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateUsersInventory: "users/inventory",
            updateList: "clientBonusSystem/index",
            store: "clientBonusSystem/store",
            update: "clientBonusSystem/update",
            setPagination: "clientBonusSystem/setPagination",
            updateSort: "clientBonusSystem/updateSort",
            updateFilter: "clientBonusSystem/updateFilter",
            updateColumn: "clientBonusSystem/updateColumn",
            updatePagination: "clientBonusSystem/updatePagination",
            show: "clientBonusSystem/show",
            empty: "clientBonusSystem/empty",
            delete: "clientBonusSystem/destroy",
            refreshData: "clientBonusSystem/refreshData",
        }),
        updateBonus(){
          this.loadingSaveData = true;
          this.update(this.selectedItem)
            .then(res => {
              this.loadingSaveData = false;
              this.drawerUpdate = false;
              this.selectedItem = null;
              this.$alert(res);
              this.fetchData();
            })
            .catch(err => {
              this.loadingSaveData = false;
              this.$alert(err);
            })
        },
        closeBonusUpdate(){
          this.drawerUpdate = false;
          this.selectedItem = null;
        },

        edit(model) {         
          this.selectedItem = JSON.parse(JSON.stringify(model));
          this.drawerUpdate = true;
        },

        deleteBonus(bonus){
          this.$confirm(
              this.$t('message.do_you_really_want_to_do_this'),
              this.$t("message.warning"), {
                confirmButtonText: this.$t("message.yes"),
                cancelButtonText: this.$t("message.no"),
                type: "warning"
              }
            )
            .then(() => {
              this.destroy(bonus);
            })
            .catch(() => {
              this.$message({
                type: "warning",
                message: this.$t("message.operation_canceled")
              });
            });
        },
        handleTabClick(tab, event){
          this.loadingData = true;
          if(tab.name == 'default'){
            this.$set(this.filterForm, 'default', true);
          }else if (tab.name == 'client'){
            this.$set(this.filterForm, 'default', false);
          }
          this.emptyForm();
        },
        fetchData() {
          const query = {...this.filter, ...this.pagination, ...this.sort,};
          this.loadingData = true;
          this.updateList(query).then(res => {
              this.loadingData = false
          }).catch(err => {
              this.loadingData = false
          });
        },
        emptyForm(){
          this.clientData = {
            userBonuses: [],
            defaultBonuses: [
              {
                user_id: null,
                percentage: 0,
                min_deal_sum: null,
                max_deal_sum: null,
                currency_id: null,
              }
            ],
          }
        },
        editDefaultBonus(default_bonus){
        },
        saveClientBonus(default_bonus){
          this.loadingSaveData = true;
          let key_name = default_bonus ? 'defaultBonuses' : 'userBonuses';
          this.$refs["form"].validate((valid) => {
            if (valid) {
              this.loadingData = true;
              this.store(this.clientData[key_name])
                .then(res => {
                  this.loadingSaveData = false;
                  this.loadingData = false;
                  this.emptyForm();
                  this.$alert(res);
                  this.fetchData();
                })
                .catch(err => {
                  this.loadingSaveData = false;
                  this.loadingData = false;
                  this.$alert(err);
                })
            }
          });
        },
        addBonus(index = null, default_bonus = false) {
          let key_name = default_bonus ? 'defaultBonuses' : 'userBonuses';
          if(index){
            this.clientData[key_name].splice((index+1), 0, JSON.parse(JSON.stringify(this.form)));
          }else{
            this.clientData[key_name].push(JSON.parse(JSON.stringify(this.form)));
          }
        },

        removeBonus(index, default_bonus = false) {
          let key_name = default_bonus ? 'defaultBonuses' : 'userBonuses';
          this.clientData[key_name].splice(index, 1);
        },

        searchInventoryWithLoad(val = ''){
          this.loadingUsers = true;
          this.searchInventory(val, this);
        },

        searchInventory: _.debounce(function(val, self) {
            self.updateUsersInventory({employee_type: 'client', per_page: 50, search: val})
              .then(() => {
                this.loadingUsers = false;
                setTimeout(() => {
                  this.selected = this.id;
                }, 100);
              })
              .catch(() => this.loadingUsers = false)
        }, 500), 

        dispatch(){

        }
    
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('reasons/EMPTY_LIST');
        next()
    },
};
</script>
<style>
.mm__create_bonus {
  display: flex; 
  position: relative; 
  align-items: center; 
  justify-content: center;
  margin: 10px 0px 10px 0px !important;
  border-radius: 5px;
}
.mm__space_beetween {
  display: flex;
  justify-content: space-between; 
  position: relative; 
  align-items: center;
}

.mm__header_bonus {
  font-weight: 700;
  font-size: 18px;
}
.mm__bonus_create_back {
  padding-top: 10px;
  width: 100%;
  min-width: 1000px;
  border-radius: 5px;
  background-color: rgb(33, 191, 253);
}

.mm__updating_row {
  background-color: rgb(33, 191, 253);
}

</style>
